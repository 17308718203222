import React from "react";
import { RouteObject } from "react-router-dom";

import Error404 from "../error/Error404/Error404";
import ErrorBoundary from "../error/ErrorBoundary/ErrorBoundary";
import billUploadRoute from "../features/BillUpload/routes";
import campaignRoute from "../features/Campaign/routes";
import campaignListRoute from "../features/CampaignList/routes";
import casaFieldsRoute from "../features/CasaFields/routes";
import cohortAnalysisRoute from "../features/CohortAnalysis/routes";
import customerAnalysisRoute from "../features/CustomerAnalysis/routes";
import customerCubeSettingsRoute from "../features/CustomerCubeSettings/routes";
import productAnalysisRoute from "../features/ProductAnalysis/routes";
import productFrequencyRoute from "../features/ProductFrequency/routes";
import settingsRoute from "../features/Settings/routes";
import storeAnalysisRoute from "../features/StoreAnalysis/routes";
import uploadDataRoutes from "../features/UploadData/routes";
import PageGuard from "../guards/PageGuard";
import DashboardLayout from "../layouts/Dashboard";
import overviewDashboardRoute from "../features/OverviewDashboard/routes";
import festivalAnalysisRoute from '../features/FestivalAnalysis/routes';
import RetenseeRoute from '../features/RetenseeSetting/routes';
import ExclusionRoute from '../features/Exclusions/routes';
import roleConfigRoute from '../features/RoleManagement/routes';
import customer360Route from  "../features/Customer360/routes";
import PosDashboard from "../layouts/Dashboard/components/PosDashboard";
import customer360POS from "../features/Customer360/routes/pos-route";
import SurveyCreator, {SurveyFormRoute} from '../features/SurveyJs/routes';
import InvoiceCreatorRoute from '../features/InvoiceCreator/routes';
import BrandThemeRoute from '../features/BrandTheme/routes';
import InvoiceRoute from '../features/InvoiceGenerator/routes';
import CopyWriteTemplateRoutes from '../features/CopyWriteTemplate/routes';
import UserManagementRoute from '../features/UserManagement/routes';
import omniChannelDashboardRoute from '../features/OmniChannelDashboard/routes';
import BlacklistConfigRoute from '../features/BlacklistManagement/routes';
import StoreManagementRoute from '../features/StoreManagement/routes';
import leadConfigRoute from '../features/CreateLead/routes';
import storeStatusReport from '../features/StoreStatusReports/routes';
import ManageLeadRoute from '../features/ManageLead/routes';
import LeadDashboardRoute from '../features/LeadDashboard/routes';
import intellicloseConfigRoute from '../features/IntellicloseConfig/routes';
import FlipSellRoute from "../features/FlipSell/Routes";
import PersonifyConfigRoute from '../features/Personify/routes';
import OneFlowOtpConfigRoute from '../features/OneFlowOtpConfig/routes';
import LoyaltyOtpConfigRoute from '../features/LoyaltyOtpConfig/routes';
import SurveySettingsRoute from "../features/SurveySettings/routes";
import journeyBuilderRoute from '../features/JourneyBuilder/routes';
import journeyListRoutes from '../features/JourneyList/routes';
import OfferOtpConfigRoute from '../features/OfferOtpConfig/routes';
import CommerceConnectSettingsRoute from "../features/CommerceConnect/Settings/routes";
import MufflerRoute from "../features/MufflerSettings/routes";
import integrationRoute from '../features/Integrations/routes';
import ReportsRoute from "../features/Reports/routes";

const routesPages: RouteObject[] = [
  omniChannelDashboardRoute,
  productFrequencyRoute,
  uploadDataRoutes,
  storeAnalysisRoute,
  billUploadRoute,
  cohortAnalysisRoute,
  campaignRoute,
  campaignListRoute,
  roleConfigRoute,
  RetenseeRoute,
  SurveySettingsRoute,
  CommerceConnectSettingsRoute,
  ExclusionRoute,
  BlacklistConfigRoute,
  UserManagementRoute,
  StoreManagementRoute,
  leadConfigRoute,
  ManageLeadRoute,
  LeadDashboardRoute,
  customerCubeSettingsRoute,
  settingsRoute,
  productAnalysisRoute,
  customerAnalysisRoute,
  casaFieldsRoute,
  overviewDashboardRoute,
  customer360Route,
  InvoiceCreatorRoute,
  BrandThemeRoute, 
  intellicloseConfigRoute,
  CopyWriteTemplateRoutes,
  SurveyCreator,
  festivalAnalysisRoute,
  storeStatusReport,
  FlipSellRoute,
  PersonifyConfigRoute,
  OneFlowOtpConfigRoute,
  LoyaltyOtpConfigRoute,
  OfferOtpConfigRoute,
  intellicloseConfigRoute,
  journeyBuilderRoute,
  ...journeyListRoutes,
  MufflerRoute,
  integrationRoute,
  ReportsRoute,
];

export const posRoutePages: RouteObject[] = [customer360POS];
const Routes: RouteObject[] = [
  InvoiceRoute,
  SurveyFormRoute,
  {
    path: "/",
    element: <DashboardLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PageGuard />,
        children: routesPages,
      },
    ],
  },
  {
    path: "/pos-dashboard/",
    element: <PosDashboard />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/pos-dashboard/",
        element: <PageGuard />,
        children: posRoutePages,
      },
    ],
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

export default Routes;
