import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { ReportTypes } from "../../../interfaces/Reports";

const ReportOutlet = lazy(() => import("../components/ReportOutlet"));

const Reports = lazy(() => import("../components/Report"));

const ReportsRoute: RouteObject = {
  path: "/reports",
  element: <ReportOutlet />,
  children: [
    {
      path: "leads/overall-lead",
      element: <Reports type={ReportTypes.OVERALL_LEAD_REPORT} />,
    },
    {
      path: "leads/store-status",
      element: <Reports type={ReportTypes.STORE_STATUS_ACTIVITY} />,
    },
    {
      path: "leads/store-category",
      element: <Reports type={ReportTypes.STORE_CATEGORY_REPORT} />,
    },
    {
      path: "leads/store-efficiency",
      element: <Reports type={ReportTypes.STORE_EFFICIENCY_REPORT} />,
    },
    {
      path: "leads/store-person-efficiency",
      element: <Reports type={ReportTypes.STORE_PERSON_EFFICIENCY_REPORT} />,
    },
    {
      path: "leads/source-efficiency",
      element: <Reports type={ReportTypes.SOURCE_EFFICIENCY_REPORT} />,
    },
    {
      path: "leads/product-efficiency",
      element: (
        <Reports type={ReportTypes.PRODUCT_HIERARCHY_EFFICIENCY_REPORT} />
      ),
    },
    {
      path: "transaction",
      element: <Reports type={ReportTypes.TRANSACTION} />,
    },
    { path: "fraud", element: <Reports type={ReportTypes.FRAUD_REPORT} /> },
    {
      path: "enrollment",
      element: <Reports type={ReportTypes.MEMBER_BILL_REPORT} />,
    },
    {
      path: "campaign",
      element: <Reports type={ReportTypes.CAMPAIGN_REPORT} />,
    },
    { path: "offer", element: <Reports type={ReportTypes.OFFER_REPORT} /> },
    {
      path: "store-health",
      element: <Reports type={ReportTypes.STORE_HEALTH_REPORT} />,
    },
    {
      path: "store-kpi",
      element: <Reports type={ReportTypes.STORE_KPI_REPORT} />,
    },
    {
      path: "loyalty-liability",
      element: <Reports type={ReportTypes.LIABILITY_SUMMARY_REPORT} />,
    },
    {
      path: "kpi",
      element: <Reports type={ReportTypes.KPI_REPORT} />,
    },
  ],
};

export default ReportsRoute;
